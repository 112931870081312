import React, { useState, useEffect } from 'react';
import styles from "./ConteoRegresivo.module.css";

const ConteoRegresivo = ({ anio, mes, dia, hora, minuto, status }) => {
  const [tiempoRestante, setTiempoRestante] = useState('Calculando...');
  const [estado, setEstado] = useState('Tiempo');

  useEffect(() => {
    // Convertir la fecha de inicio del juego a la zona horaria de Puerto Rico
    const fechaObjetivo = new Date(Date.UTC(anio, mes - 1, dia, hora, minuto, 0));
    
    // Obtener la hora actual en la zona horaria de Puerto Rico
    const obtenerHoraActualPuertoRico = () => {
      const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/Puerto_Rico',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // Para usar formato de 24 horas
      });

      const partesFecha = formatter.formatToParts(new Date());
      const anioActual = partesFecha.find(p => p.type === 'year').value;
      const mesActual = partesFecha.find(p => p.type === 'month').value;
      const diaActual = partesFecha.find(p => p.type === 'day').value;
      const horaActual = partesFecha.find(p => p.type === 'hour').value;
      const minutoActual = partesFecha.find(p => p.type === 'minute').value;
      const segundoActual = partesFecha.find(p => p.type === 'second').value;

      return new Date(Date.UTC(anioActual, mesActual - 1, diaActual, horaActual, minutoActual, segundoActual));
    };

    const calcularTiempoRestante = () => {
      const ahoraPuertoRico = obtenerHoraActualPuertoRico();
      const tiempoRestante = fechaObjetivo.getTime() - ahoraPuertoRico.getTime();

      if (tiempoRestante <= 0) {
        clearInterval(intervalo);
        setTiempoRestante(status === "Final" ? '¡Juego terminado!' : '¡Juego Iniciado!');
      } else {
        const dias = Math.floor(tiempoRestante / (1000 * 60 * 60 * 24));
        const horas = Math.floor((tiempoRestante % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutos = Math.floor((tiempoRestante % (1000 * 60 * 60)) / (1000 * 60));
        const segundos = Math.floor((tiempoRestante % (1000 * 60)) / 1000);

        if (dias <= 0 && horas <= 0 && minutos <= 59) {
          setEstado("Finalizando");
          setTiempoRestante(`${dias} días, ${horas}HH :${minutos}min : ${segundos} seg`);
        } else {
          setTiempoRestante(`${dias} días, ${horas}HH :${minutos}min : ${segundos} seg`);
        }
      }
    };

    const intervalo = setInterval(calcularTiempoRestante, 1000);

    return () => clearInterval(intervalo);
  }, [anio, mes, dia, hora, minuto, status]);

  return (
    <div>
      <span className={estado === "Finalizando" ? styles.text_min2 : styles.text_min}>
        {tiempoRestante}
      </span>
    </div>
  );
};

export default ConteoRegresivo;
