import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../API";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import styles from "./PopularTournaments.module.css";
import fondo from "../images/background.png";
import left_arrow from "../images/slider-left-arrow.png";
import right_arrow from "../images/slider-right-arrow.png";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import "./PopularTournaments.css";

function PopularTournaments() {
  const swiperRef = useRef(null);
  const Navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [ApiAllData, setApiAllData] = useState([]);

  const getGames = async () => {
    try {
      setLoading(true);
      const response = await axios.get("get-tournaments");
      setApiAllData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error loading data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getGames();
  }, []);

  const handleButtonClick = (item) => {
    if (localStorage.getItem("login") === "Ok") {
      if (item.type) {
        const canEdit = true; 
        localStorage.setItem("canEdit", canEdit);
        if (item.game_id) {
          localStorage.setItem("game-id", item.game_id);
        }
        localStorage.setItem("game-pos", "1");
        localStorage.setItem("tournament-id", item.idTournament);
        localStorage.setItem("statusGame", "0");
        Navigate("/GameInfo/Games");
      } else {
        Navigate("/Juegos/all");
      }
    } else {
      Navigate("/Iniciesesion");
    }
  };

  const MonstarTournamnets = () => {
    return (
      <div
        className={styles.contenedorGlobal}
        style={{
          backgroundImage: `url(${fondo})`,
        }}
      >
        <div className={styles.contenTiitle}>
          <div className={styles.contentTxtTour}>
            <h1 className={styles.txtTournaments}>Torneos Populares</h1>
          </div>
          <div className={styles.contenLinear}>
            <div className={styles.linearGreen}></div>
          </div>
        </div>
        <div className="sec-3-slider-parent-div white">
          <div
            style={{ width: "100px", height: "50px" }}
            className="d-flex justify-content-center align-items-center"
          >
            <img
              src={left_arrow}
              width="100%"
              alt="Previous Slide"
              className="cursor--"
              onClick={() => swiperRef.current.swiper.slidePrev()}
            />
          </div>
          <div className="width-responsive-for-slider">
            <Swiper
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              loop={true}
              modules={[Autoplay]}
              className="mySwiper"
              ref={swiperRef}
            >
              {ApiAllData.map((item, index) => (
                <SwiperSlide key={index} className="w-100">
                  <div
                    className="main-game-card-body cursor--"
                    style={{
                      borderRadius: "12px",
                      border: "none",
                    }}
                  >
                    <div className={styles.cardBlue}>
                      <div className={styles.cardContent}>
                        <div className={styles.imagenCard}>
                          <img
                            src={item.image}
                            className={styles.imagenCard_vista}
                            alt="Tournament"
                          />
                        </div>
                        <div className={styles.dataCard}>
                          <div className={styles.subData_card}>
                            <div>
                              <span className={styles.txt_name}>
                                {item.name}
                              </span>
                            </div>
                            <div>
                              <span className={styles.txtDescripcion}>
                                {item.descripcion}
                              </span>
                            </div>
                            {item.users_answered && item.members && (
                              <div className={styles.participantInfo}>
                                <span>
                                  Participantes: {item.users_answered.length} /{" "}
                                  {item.members}
                                </span>
                              </div>
                            )}
                            {item.price && (
                              <div className={styles.entryFee}>
                                <span>Entry Fee: {item.price}</span>
                              </div>
                            )}
                          </div>
                          <div className={styles.div_btn}>
                            <button
                              onClick={() => handleButtonClick(item)}
                              className={styles.btn_card}
                            >
                              {item.type ? "Juega ahora" : "Ver torneo"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div
            style={{ width: "100px" }}
            className="d-flex justify-content-center align-items-center"
          >
            <img
              src={right_arrow}
              width="100%"
              alt="Next Slide"
              className="cursor--"
              onClick={() => swiperRef.current.swiper.slideNext()}
            />
          </div>
        </div>
        <div className="sec3 pt-4 white">
          <h4 className="cursor--" onClick={() => Navigate("/Juegos")}>
            {"<<Ver Todos>>"}
          </h4>
        </div>
      </div>
    );
  };

  return (
    <>
      {ApiAllData.length > 0 && !Loading ? (
        <MonstarTournamnets />
      ) : (
        <></>
      )}
      {Loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
}

export default PopularTournaments;
