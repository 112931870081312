import React from 'react'

const Error = () => {
    return (
        <>
            <div id="main">
                <div className='fof'>
                    <h2 style={{fontSize: '70px'}}>Error 404</h2>
                    <h1>Page Not Found</h1>
                </div>
            </div>
        </>
    )
}

export default Error