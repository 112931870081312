import axios from "axios";

const API = axios.create({
  baseURL: "https://panel.lotoxport.com/api",
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

export default API;
