import React from "react";
import Cards from "./Cards";

const Section3 = () => {
  return (
    <div>
      <div className="container item-center">
        <p className="mt-5 section3 font-size-sec3 color-blue">
          JUEGA Y GANA <br />
          <span className="color-green border_bottom">
            <>CON LOTOXPORT</>
          </span>
        </p>
      </div>

      <div className="container mt-5 mb-5"></div>

      <div className="container pe-4 ps-4">
        <h1 className="color-green size-sec3">¿Qué es LotoXport?</h1>
      </div>

      <div className="container mt-5 mb-5"></div>

      <div className="container pe-4 ps-4">
        <p className="color-blue small-size-sec3">
        ¿Te apasiona el deporte y te gusta la emoción de ganar? Entonces, LotoXport es la plataforma perfecta para ti. En LotoXport,
        te ofrecemos la oportunidad de participar en emocionantes sorteos deportivos donde cada jugada te acerca más a la
        victoria. No solo se trata de ver a tus equipos favoritos competir, sino de ser parte activa del juego, haciendo que cada
        momento cuente.
        En LotoXport entendemos la pasión que sientes por el deporte. Ya sea que tu corazón lata más rápido con un gol en el último
        minuto o que te emociones al ver un triple decisivo en baloncesto, sabemos que cada instante es único. Por eso, hemos
        diseñado una plataforma que te permite vivir esa pasión al máximo, dándote la oportunidad de ganar mientras sigues tus
        deportes favoritos.
        </p>
      </div>

      {/*<div className="item-center">
        <div className="mt-5 mb-5 bottom_border"></div>
      </div>*/}
      <div className="container mt-5 mb-5"></div>

      {/*<div className="container">
        <Cards />
      </div>*/}

      <div className="container mt-5 mb-5"></div>

      {/*<div className="container item-center">
        <span
          style={{ textAlign: "center", fontSize: "24px", color: "#979797" }}
        >
          What is Lorem Ipsum?
          <br />
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </span>
      </div>*/}

      <div className="container mt-5 mb-5 pt-5"></div>
    </div>
  );
};

export default Section3;
