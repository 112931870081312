import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import img from "../../images/default-profile.png";
import { useNavigate } from "react-router-dom";
import axios from "../../API";
import { useFormik } from "formik";
import { ProfileSchema } from "../../Schemas";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "./Myprofile.css";
import { toast } from "react-toastify";

const MyProfile = () => {
  const [userData, setUserData] = useState({
    phone: "",
    address: "",
    postal_code: "",
    dob: ""
  });
  const initialValues = {
    Telephone: "",
    Address: "",
    Village: "",
    Pincode: "",
  };

  const UserData = JSON.parse(localStorage.getItem("user-info"));
  const [IsError, setIsError] = useState("");
  const [ApiUserData, setApiUserData] = useState({});
  const [profile_image, setprofile_image] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [Loading, setLoading] = useState(false);
  const [Villages, setVillages] = useState([]);
  const [CurrVillageName, setCurrVillageName] = useState(UserData?.village?.name);

  const Navigate = useNavigate();
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: ProfileSchema,
    onSubmit: async (values) => {
      console.log("Submit del formulario ejecutado");
      try {
        setLoading(true);
        const selectedVillage = Villages.find(village => village.town === values.Village);
        const villageName = selectedVillage ? selectedVillage.town : "";

        const res = await axios.post(
          "/update-profile",
          {
            profile_image: profile_image,
            phone: values.Telephone,
            address: values.Address,
            postal_code: values.Pincode,
            village: villageName, // Enviar el nombre del pueblo
            auth_token: localStorage.getItem("token"),
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(res);
        if (res.data.status === "1") {
          console.log("ver respuesta cambio", res.data);
          localStorage.setItem("imageUser", res.data.data.profile_image);
          console.log(localStorage.getItem("imageUser"));
          console.log("cambio datos");
          setLoading(false);
          toast.success("Perfil actualizado con éxito");
          Navigate("/Dashboard/Profile");
        } else if (res.data.errors === "The village field is required.") {
          toast.success("Es necesario que seleccione un pueblo");
          setLoading(false);
          return;
        }
      } catch (error) {
        console.log("🚀 error", error);
        if (error) {
          setLoading(false);
        }
      }
    },
    enableReinitialize: true,
  });

  const GetVillage = async () => {
    return axios
      .get("/town")
      .then((res) => {
        console.log(res.data);
        setVillages(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUser = () => {
    setLoading(false);
    const MappedValues = {
      Telephone: userData.phone,
      Address: userData.address,
      Village: userData.village ? userData.village : "", // Guardar nombre del pueblo
      Pincode: userData.postal_code,
    };
    setValues(MappedValues);
  };

  useEffect(() => {
    GetVillage();
  }, []);

  useEffect(() => {
    const getUserData = async () => {
      setLoading(true);
      try {
        const authToken = localStorage.getItem('token');
        if (authToken) {
          const response = await axios.post("/get-user", { auth_token: authToken });
          if (response.data && response.data.data) {
            const userDataFromApi = response.data.data;
            setUserData(userDataFromApi);

            const villageName = userDataFromApi.village ? userDataFromApi.village : "";

            setValues({
              Telephone: userDataFromApi.phone || "",
              Address: userDataFromApi.address || "",
              Pincode: userDataFromApi.postal_code || "",
              Village: villageName || "",
              
            });
          }
        } else {
          console.error('No se encontró la cookie token en el almacenamiento.');
        }
      } catch (error) {
        console.error('Error al obtener los datos del usuario:', error);
      } finally {
        setLoading(false);
      }
    };

    getUserData();
  }, [setValues]);

  return (
    <>
      <>
        <div className="item-center">
          <div className="">
            <span className="color-blue font-size-dash">Información personal</span>
          </div>
        </div>
        <div>
          <form
            onSubmit={handleSubmit}
            autoComplete="off"
            className="form_center"
          >
            <div className="separadorLabel">
              <span className="labelName">{localStorage.getItem("username")}</span>
            </div>

            <div className="image-upload">
              <label className="cursor--" htmlFor="file-input">
                {profile_image ? (
                  <img
                    src={URL.createObjectURL(profile_image)}
                    alt=""
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <img
                    src={userData.profile_image ? userData.profile_image : img}
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
              </label>
              <input
                onChange={(e) => {
                  setprofile_image(e.target.files[0]);
                }}
                className="d-none"
                id="file-input"
                type="file"
                accept="image/*"
              />
            </div>
            <div>
              <span className="color-blue" style={{ fontSize: "20px" }}>
                Foto de perfil
              </span>
            </div>
            <div className="mb-4"></div>

            <div className="containerTxtInf">
              <div className="containerLinear2">
                <span>Los siguientes datos no pueden ser cambiados.</span>
              </div>
            </div>

            <div className="containerData">
              <span className="laberBox">{"Nombre y apellidos: "}{localStorage.getItem("name")}</span>
            </div>

            <div className="containerData">
              <span className="laberBox">{"Email: "}{localStorage.getItem("email")}</span>
            </div>

            <div className="containerData">
              <span className="laberBox">Fecha de nacimiento: {userData.dob}</span>
            </div>

            <div className="containerTxtInf2">
              <div className="containerLinearx">
                <span className="txtInfEdi">Datos editables</span>
              </div>
            </div>

            <Form.Group className="form-field-width-incre">
              <Form.Floating className="mb-3 _form">
                <Form.Control
                  value={values.Telephone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  name="Telephone"
                  id="phone-dash"
                  type="number"
                  placeholder={userData.phone}
                  className="form-control-blue input---"
                />
                <label className="white" htmlFor="phone-dash">
                  Teléfono
                </label>
                {errors.Telephone && touched.Telephone ? (
                  <p className="form-errors">{errors.Telephone}</p>
                ) : null}
              </Form.Floating>
            </Form.Group>

            <Form.Group className="form-field-width-incre">
              <Form.Floating className="mb-3 _form">
                <Form.Control
                  value={values.Address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  id="address-dash"
                  name="Address"
                  type="text"
                  placeholder="address"
                  className="form-control-blue input---"
                />
                <label className="white" htmlFor="address-dash">
                  Dirección
                </label>
                {errors.Address && touched.Address ? (
                  <p className="form-errors">{errors.Address}</p>
                ) : null}
              </Form.Floating>
            </Form.Group>

            <Form.Group className="form-field-width-incre">
              <Form.Floating className="mb-3 _form">
                <Form.Select
                  name="Village"
                  value={values.Village}
                  onChange={(e) => {
                    const selectedVillageName = e.target.value;
                    setCurrVillageName(selectedVillageName);
                    setFieldValue("Village", selectedVillageName);
                  }}
                  onBlur={handleBlur}
                  className="form-control-blue input---"
                >
                  <option value="">Seleccione un pueblo</option>
                  {Villages.map((item, index) => (
                    <option key={index} value={item.town}>
                      {item.town}
                    </option>
                  ))}
                </Form.Select>
                <label className="white" htmlFor="village-dash">
                  Pueblo
                </label>
                {errors.Village && touched.Village ? (
                  <p className="form-errors">{errors.Village}</p>
                ) : null}
              </Form.Floating>
            </Form.Group>

            <Form.Group className="form-field-width-incre">
              <Form.Floating className="mb-3 _form">
                <Form.Control
                  value={values.Pincode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  name="Pincode"
                  id="pincode-dash"
                  type="number"
                  placeholder="pincode"
                  className="form-control-blue input---"
                />
                <label className="white" htmlFor="pincode-dash">
                  Código postal
                </label>
                {errors.Pincode && touched.Pincode ? (
                  <p className="form-errors">{errors.Pincode}</p>
                ) : null}
              </Form.Floating>
            </Form.Group>

            <div className="mb-3">
              <div className="item-center mt-3">
                <Button className="color-green-btn" type="submit">
                  Guardar
                </Button>
              </div>
            </div>
          </form>
        </div>
      </>
      {Loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default MyProfile;
