import React, { Fragment, useState, useRef, useEffect } from "react";
import imgLogo from "../../../images/Hand_img.png";
import axios from "../../../API";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ImgBaseUrl } from "../../../ImageBaseUrl";
import styles from "./Tab3Content.module.css";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaQuestionCircle } from 'react-icons/fa';  
import ReactTooltip from 'react-tooltip';  


// Editor de texto para el desempate (typebreak)
const TextEditor = ({ value, onChange, onBlur, index }) => {
  const inputRef = useRef(null);
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value]);

  const handleInputChange = (event) => {
    const newValue = event.target.value.replace(/[^0-9]/g, '');
    setLocalValue(newValue);
  };

  const handleBlur = () => {
    onBlur(localValue);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <textarea
      ref={inputRef}
      id={`text-editor-${index}`}
      className={styles.newEditor}
      placeholder="Escribe aquí..."
      value={localValue}
      onChange={handleInputChange}
      onBlur={handleBlur}
    ></textarea>
  );
};


const Tab3Content = () => {
  const ico = (
    <svg
      width="13"
      height="29"
      viewBox="0 0 23 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6774 17.4594L5.40486 1.21565C5.13759 0.946185 4.81961 0.732302 4.46927 0.586342C4.11892 0.440383 3.74314 0.365234 3.36361 0.365234C2.98407 0.365234 2.60829 0.440383 2.25795 0.586342C1.9076 0.732302 1.58963 0.946185 1.32236 1.21565C0.786885 1.75432 0.486328 2.483 0.486328 3.24253C0.486328 4.00206 0.786885 4.73074 1.32236 5.26941L15.5536 19.6444L1.32236 33.8757C0.786885 34.4143 0.486328 35.143 0.486328 35.9025C0.486328 36.6621 0.786885 37.3907 1.32236 37.9294C1.58862 38.2011 1.90615 38.4172 2.25654 38.5652C2.60694 38.7133 2.98322 38.7903 3.36361 38.7919C3.74399 38.7903 4.12028 38.7133 4.47067 38.5652C4.82106 38.4172 5.13859 38.2011 5.40486 37.9294L21.6774 21.6857C21.9692 21.4164 22.2021 21.0897 22.3614 20.726C22.5207 20.3623 22.6029 19.9696 22.6029 19.5725C22.6029 19.1755 22.5207 18.7827 22.3614 18.4191C22.2021 18.0554 21.9692 17.7286 21.6774 17.4594Z"
        fill="#02A551"
      />
    </svg>
  );

  const [inputValue, setInputValue] = useState(""); // Maneja el valor de typebreak
  const [Loading, setLoading] = useState(false);
  const [ALLApiData, setALLApiData] = useState([]);
  const [AnswersData, setAnswersData] = useState({});
  const [Page, setPage] = useState(1);
  const [typebreak, setTypebreak] = useState(""); // Nuevo estado para typebreak
  const [typebreakTittle, setTypebreakTittle] = useState("");
  const [typebreakText, setTypebreakText] = useState("");

  const User_Data = JSON.parse(localStorage.getItem("user-info"));
  const category_id = localStorage.getItem("category-id");
  const [Visivility, setVisivility] = useState("Question");
  const [NumAnswer, setNumAnswer] = useState(100);
  const [NumGameWin, setNumGameWin] = useState(100);
  const [NumSelect, setNumSelect] = useState(100);
  const [CambioPage, setCambioPage] = useState(true);
  const [AnswerQ, setAnswerQ] = useState([]);
  const [TxtAnswerQ, setTxtAnswerQ] = useState([]);
  const [Enpoint, setEnpoint] = useState("");
  const [MsgFormulario, setMsgFormulario] = useState("");
  const [Alert, setAler] = useState(false);
  const [ConfirAlert, setConfirAler] = useState(false);
  const [VerButton, setVerButton] = useState(false);
  const [textValues, setTextValues] = useState([]);
  const [ejmTextValues, setEjmTextValues] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isClickable, setIsClickable] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);  // Estado para controlar la visibilidad del tooltip
  const [canEdit, setCanEdit] = useState(false);  // Estado para controlar la visibilidad del tooltip
  const [highlightError, setHighlightError] = useState(false);

  
  

  
  const gameStatus = localStorage.getItem("gameStatus");
  useEffect(() => {
    const checkGameStatus = async () => {
      const gameId = localStorage.getItem("game-id");
      try {
        const response = await axios.post("https://panel.lotoxport.com/api/check-status", {
          gameId: gameId
        });
  
        if (response.data.status === "Scheduled") {
          setIsClickable(true); // Habilitar interactividad si el juego está en "Scheduled"
        } else {
          setIsClickable(false); // Deshabilitar interactividad para otros estados
        }
      } catch (error) {
        console.error("Error al verificar el estado del juego:", error);
        setIsClickable(false); // Deshabilitar interactividad en caso de error
      }
    };
  
    checkGameStatus(); // Llamar a la función para verificar el estado del juego
  }, []);





  useEffect(() => {
    const getTypebreakData = async () => {
      const gameId = localStorage.getItem("game-id");
      try {
        const response = await axios.get("https://panel.lotoxport.com/api/get-typebreak/"+gameId);
        setTypebreakTittle(response.data[0].name); // Guardar el valor de typebreak
        setTypebreakText(response.data[0].description); // Guardar el valor de typebreak


      } catch (error) {
        console.error("Error al obtener el typebreak:", error);
        
      }
    };
  
    getTypebreakData(); 
  }, []);
  

  var txtButton = "Siguiente >>";

  const getData = async (w) => {
    try {
      setLoading(true);
      var idSearch = localStorage.getItem("game-id");

      const enpoint = "get-questions/" + `${idSearch}`;

      const res = await axios.get(enpoint, {
        page: `${w}`,
      });
      setALLApiData(res.data);
    
      var idGame = localStorage.getItem("game-id");
      var idUser = localStorage.getItem("idUser");

      var answerW = [];
      var txtAnsweW = [];

      if (localStorage.getItem("editarJuego") === "Si") {
        setEnpoint("/edit-questionary");
        setMsgFormulario("Tus preguntas se editaron correctamente");
        getAnswers();
      } else {
        setEnpoint("/validate-questionary");
        setMsgFormulario("¡Felicidades! Ya estás jugando.");
        for (var i = 0; i < res.data.length; i++) {
          answerW.push(100);
          txtAnsweW.push("No respondido");
        }
        setAnswerQ(answerW);
        setTxtAnswerQ(txtAnsweW);
        setTextValues(Array(res.data.length).fill(""));
        setEjmTextValues(Array(res.data.length).fill(""));
        setNumAnswer(100);
        setNumGameWin(100);
      }

      localStorage.setItem("cuestionarios", JSON.stringify(res.data));

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getAnswers = async () => {
    try {
      const responsy = await axios.post("user-entries", {
        game_id: localStorage.getItem("game-id"),
        auth_token: localStorage.getItem("token"),
        gameUser_id : localStorage.getItem('gameUser_id'),
      });
      // console.log(responsy.data)
      // console.log("174")

      const typebreakValue = responsy.data.user_answers_array.typebreak; // Obtener el typebreak
      // console.log(typebreakValue)
      setTypebreak(typebreakValue); // Guardar el valor de typebreak

      var txtAnsweW = responsy.data.user_answers_array.user_answers;
      var answerW = responsy.data.user_answers_array.user_answers_array;
      setAnswerQ(answerW);
      setTxtAnswerQ(txtAnsweW);
    } catch (error) {
      console.log("🚀errorAnswer....", error);
    }
  };

  const handleInputChange = (newInputValue) => {
    setInputValue(newInputValue);
  };
  const handleInputBlur = (newInputValue) => {
    setInputValue(newInputValue); 
  };

  useEffect(() => {
    getData(1);
  }, []);

  useEffect(() => {
    setInputValue(typebreak); // Mostrar typebreak cuando se carguen respuestas previas
  }, [typebreak]);

  useEffect(() => {
    getData(1);
  }, []);

  useEffect(() => {
    Visibilidad();
  }, [Visivility]);

  useEffect(() => {
    MostrarLoading();
  }, [Loading]);

  useEffect(() => {
    VisibilityQuestions();
  }, [AnswerQ]);

  useEffect(() => {
    NewDiseño();
  }, [VerButton]);

  const Navigate = useNavigate();

  const Visibilidad = () => {
    if (Visivility === "Question") {
      return <VisibilityQuestions avalible={true} />;
    } else if (Visivility === "Answers" || Visivility === "NextAnswer") {
      SaveAnswers();
      return (
        <div
          id="container_answers"
          className={styles.content_max}
        >
          <div className={styles.divClose}>
            <span
              className={styles.txtClose}
              onClick={(event) => {
                setVisivility("Question");
              }}
            >
              {"Cerrar"}
            </span>
          </div>
          <VisibilityAnswers/>
        </div>
      );
    }
  };

  const SaveAnswers = () => {
    setAnswersData(ALLApiData[localStorage.getItem("n_question")]);
  };

  useEffect(() => {
    VisibilityQuestions();
  }, [NumAnswer]);

  const ButtonVisibility = () => {
    if (ALLApiData.length - 1 > parseInt(localStorage.getItem("n_question"))) {
      setNumSelect(parseInt(localStorage.getItem("n_question")));
      setCambioPage(true);
      txtButton = "Siguiente";
    } else {
      setCambioPage(false);
      txtButton = "Finalizar";
    }

    if (NumAnswer === 100) return null;

    return (
      <div className={styles.contentButtonNext}>
        <div
          className={styles.contenAnsweButtonNext}
          onClick={(event) => {
            if (CambioPage) {
              localStorage.setItem("n_question", NumSelect + 1);
              setNumGameWin(100);
              if (Visivility === "Answers") {
                setVisivility("NextAnswer");
              } else if (Visivility === "NextAnswer") {
                setVisivility("Answers");
              }
            } else {
              setVisivility("Question");
              setNumGameWin(100);
            }
          }}
        >
          <span className={styles.txtAnswers}>{txtButton}</span>
        </div>
      </div>
    );
  };

  const EnviarAnswer = async () => {
    setAler(false);
    setLoading(true);
    try {
      const response = await axios.post(Enpoint, {
        game_id: localStorage.getItem("game-id"),
        user_answers: AnswerQ,
        answers: TxtAnswerQ,
        typebreak: inputValue,
        auth_token: localStorage.getItem("token"),
        gameUser_id : localStorage.getItem('gameUser_id'),
      });
      setLoading(false);
      if (response.status === 201) {
        toast.success(MsgFormulario);
        setConfirAler(true);
      } else if (response.status === 200) {
        toast.success(MsgFormulario);
        setConfirAler(true);
      } else {
        setVisivility("Question");
        toast.success(`No pudimos procesar tu solicitud`);
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        toast.warning('Has alcanzado el límite máximo de entradas para este juego');
        setLoading(false);
        setConfirAler(false);
      } else {
        setVisivility("Question");
        toast.success(`Estamos presentando problemas...`);
        setLoading(false);
      }
    }
  };
  
  const AletrtDialogConfir = () => {
    if (ConfirAlert) {
      return (
        <div>
          <Backdrop
            sx={{
              color: "#F0993F",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <div className={styles.contenedorAlert}>
              <div className={styles.subContenedorAlert2}>
                <div className={styles.contentImgLogox}>
                  <div className={styles.subcontetImgLogo}>
                    <img className={styles.imagenLogo} src={imgLogo} />
                  </div>
                </div>
                <div className={styles.contentImgLogox1}>
                  <span className={styles.txtPicks}>Picks sometidas</span>
                </div>
                <div className={styles.contentImgLogox2}>
                  <span className={styles.txtSubPicks}>
                    No olvides que las preguntas cierran el día{" "}
                  </span>
                  <span className={styles.txtSubPicks2}>
                    {localStorage.getItem("fechaJuego")}{" "}
                  </span>
                  <span className={styles.txtSubPicks}>
                    Podrás editar tus picks antes que cierre, una vez haya
                    comenzado no podrás editarlo. Regresa luego que cierre para
                    que veas si ganaste. ¡Te deseamos éxito!
                  </span>
                </div>
                <div className={styles.contentButonAlertConfir}>
                  <div
                    className={styles.contenButtonSendAlert2}
                    onClick={() => {
                      setConfirAler(false);
                      Navigate("/Dashboard/Picks");
                    }}
                  >
                    <span className={styles.txtAnswers}>{"Ver tus picks"}</span>
                  </div>
                </div>
                <div className={styles.contentButonAlertConfir}>
                  <div
                    className={styles.contenButtonSendAlert22}
                    onClick={() => {
                      Navigate("/Juegos/all");
                    }}
                  >
                    <span className={styles.txtAnswers}>
                      {"Ver más partidos"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Backdrop>
        </div>
      );
    } else {
      return null;
    }
  };

  const AlertDialogPerson = () => {
    if (Alert) {
      const lastItem = ALLApiData[ALLApiData.length - 1];

      return (
        <div>
          <Backdrop
            sx={{
              color: "#F0993F",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <div className={styles.contenedorAlert}>
              <div className={styles.subContenedorAlert}>
                <div className={styles.contentImgLogoAlert}>
                  <div className={styles.subcontetImgLogo}>
                    <img className={styles.imagenLogo} src={imgLogo} />
                  </div>
                  <div
                    className={styles.contentCerrar}
                    onClick={() => {
                      setAler(false);
                    }}
                  >
                    <span className={styles.txtCerrarAlert}> X </span>
                  </div>
                </div>
                <div className={styles.contentImgLogo1}>
                  <span className={styles.txtPicks}>Picks sometidas</span>
                </div>
                <div className={styles.contentImgLogo2}>
                  <span className={styles.txtSubPicks}>
                    Aquí te incluimos un resumen de tus picks para que confirmes
                  </span>
                </div>
                <div className={styles.contentRespuestas}>
                  <div className={styles.contenSubRespuestas}>
                    {TxtAnswerQ.map((item, index) => (
                      <div
                        key={index}
                        className={styles.contentVisibityRespuestas}
                      >
                        <div className={styles.divisorRespuestas}>
                          <div className={styles.contentTxtNumberRes}>
                            <span className={styles.txtNumRes}>
                              {index + 1}.
                            </span>
                          </div>
                          <div className={styles.contentTxtRespuesta}>
                            <div className={styles.contetSubTxtRespuesta}>
                              <span className={styles.txtPreguntaAlert}>
                                {ALLApiData[index].game_ques}
                              </span>
                            </div>
                            <div>
                              <span className={styles.txtRespuestaAlert}>
                                {" "}Su respuesta: {item}{" "}
                              </span>
                            </div>
                          </div>
                          <div
                            className={styles.contentTxtArrow}
                            onClick={() => {
                              if (isClickable) {
                                setAler(false);
                                localStorage.setItem("n_question", index);
                                setNumSelect(index);
                                setVisivility("Answers");
                              }
                            }}
                          >
                            <div className={styles.imgArrow}>{ico}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {inputValue && (
                      <div className={styles.contentVisibityRespuestas}>
                        <div className={styles.divisorRespuestas}>
                          <div className={styles.contentTxtNumberRes}>
                            <span className={styles.txtNumRes}>
                              {TxtAnswerQ.length + 1}.
                            </span>
                          </div>
                          <div className={styles.contentTxtRespuesta}>
                            <div className={styles.contetSubTxtRespuesta}>
                              <span className={styles.txtPreguntaAlert}>
                                {typebreakTittle}
                              </span>
                            </div>
                            <div>
                              <span className={styles.txtRespuestaAlert}>
                                {" "}Su respuesta: {inputValue}{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={styles.contentButonAlertConfir}>
                    <div
                      className={styles.contenButtonSendAlert}
                      onClick={() => {
                        if (isClickable) {
                          EnviarAnswer();
                        }
                      }}
                    >
                      <span className={styles.txtAnswers}>{"SOMETER"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Backdrop>
        </div>
      );
    } else {
      return null;
    }
  };
  
  const TextAnswerColor = () => {
    return (
      <div className={styles.contentDivAnswer}>
        {AnswersData.game_ans.map((item, index) => (
          <div
            key={index}
            className={
              NumAnswer === index ? styles.contenAnswe21 : styles.contenAnswe
            }
            onClick={(event) => {
              if (isClickable) {
                setNumAnswer(index);
                setNumGameWin(index);
                guardarRespuesta(index, item);
              }
            }}
          >
            <span className={styles.txtAnswers}>{item}</span>
          </div>
        ))}
      </div>
    );
  };

  const guardarRespuesta = (numAns, txtAns) => {
    var arrayQ = AnswerQ;
    var txtArrayQ = TxtAnswerQ;
    arrayQ.splice(parseInt(localStorage.getItem("n_question")), 1, numAns);
    txtArrayQ.splice(parseInt(localStorage.getItem("n_question")), 1, txtAns);
    setAnswerQ(arrayQ);
    setTxtAnswerQ(txtArrayQ);
  };

  const NewGuardarRespuesta = (numAns, txtAns, posicion) => {
    var arrayQ = AnswerQ;
    var txtArrayQ = TxtAnswerQ;
    arrayQ.splice(posicion, 1, numAns);
    txtArrayQ.splice(posicion, 1, txtAns);
    setAnswerQ(arrayQ);
    setTxtAnswerQ(txtArrayQ);
    setVerButton(!VerButton);
  };


  

  const handleButtonClick = (index) => {
    console.log(`Texto ingresado en posición ${index}:`, textValues[index]);
    console.log("Mirar takeBraker: " + textValues);
  };

  const NewDiseño = () => {
    console.log("Entrando en NewDiseño");
  
    const juegos = ALLApiData.filter(item => item.type === "juego");
    const otros = ALLApiData.filter(item => item.type !== "juego");
    const canEdit = localStorage.getItem("canEdit") === "true";  // Comprobar si canEdit está habilitado
  
    console.log("Juegos filtrados:", juegos);
    console.log("Otros elementos filtrados:", otros);
    console.log("canEdit:", canEdit);
  
    return (
      <div className={styles.newContentMax}>
        {juegos.map((item, index) => (
          <Fragment key={index}>
            {canEdit === false && (
              <>
                {console.log(`1.canEdit === false | No editable - Juego ${index + 1}`)}
                <div className={styles.newContent_answwer_final}>
                  <div className={styles.newContainerImages_final}>
                    <div className={styles.newConImage_final}>
                      <div
                        className={styles.newImg}
                        style={{
                          background: `url(${item.qsn_imgs[0]})`,
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      />
                      <br />
                      <div className={styles.game_results}>
                        <strong>{item.game_result_1}</strong>
                        {parseFloat(item.game_result_1) > parseFloat(item.game_result_2) && (
                          <>
                            <span className={styles.winnerLabel}> Ganador</span>
                          </>
                        )}
                      </div>
                    </div>
                    {"VS"}
                    <div className={styles.newConImage_final}>
                      <div
                        className={styles.newImg}
                        style={{
                          background: `url(${item.qsn_imgs[1]})`,
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      />
                      <br />
                      <div className={styles.game_results}>
                        <strong>{item.game_result_2}</strong>
                        {parseFloat(item.game_result_2) > parseFloat(item.game_result_1) && (
                          <>
                            <span className={styles.winnerLabel}> Ganador</span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr className={styles.divider} />
                </div>
                {!isClickable && item.typebreak && (
                  <Fragment>
                    <div className={styles.newContent_answwer_final}>
                      <div className={styles.newContainerImages_final}>
                        <div className={styles.typebreakResults}>
                          <strong>Resultados typebreak:</strong>
                          <span>{` ${item.game_result_1+item.game_result_2}`}</span>
                        </div>
                      </div>
                    </div>
                    {/* Agregamos una nueva división también para el typebreak */}
                    <hr className={styles.divider} />
                  </Fragment>
                )}
              </>
            )}
  
            {canEdit && (
              <>
                {console.log(`2.canEdit && |Editable - Juego ${index + 1}`)}
                {!isClickable && (
                  <>
                    {console.log("3.!isClickable && |No es clickeable")}
                    <div className={styles.newContent_answwer_final}>
                      <div className={styles.newContainerImages_final}>
                        <div className={styles.newConImage_final}>
                          <div
                            className={styles.newImg}
                            style={{
                              background: `url(${item.qsn_imgs[0]})`,
                              backgroundSize: "contain",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                          <br />
                          <div className={styles.game_results}>
                            <strong>{item.game_result_1}</strong>
                            {parseFloat(item.game_result_1) > parseFloat(item.game_result_2) && (
                              <>
                                <span className={styles.winnerLabel}> Ganador</span>
                              </>
                            )}
                          </div>
                        </div>
                        {"VS"}
                        <div className={styles.newConImage_final}>
                          <div
                            className={styles.newImg}
                            style={{
                              background: `url(${item.qsn_imgs[1]})`,
                              backgroundSize: "contain",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                          <br />
                          <div className={styles.game_results}>
                            <strong>{item.game_result_2}</strong>
                            {parseFloat(item.game_result_2) > parseFloat(item.game_result_1) && (
                              <>
                                <span className={styles.winnerLabel}> Ganador</span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <hr className={styles.divider} />
                    </div>
                  </>
                )}
  
                {isClickable && (
                  <div className={styles.newContent_answwer}>
                  <div className={styles.gameHeader}>
                    <span className="game-time">
                      {item.time_start.split(':').slice(0, 2).join(':')}
                      {" "}
                      {item.date_start.split('-').slice(1, 3).join('/')}
                    </span>
                  </div>
                  <div className={styles.newContentJuego}>
                    <div className={styles.newContainerElements}>
                      <div className={styles.newSubContainerElements}>
                        <div className={styles.newContainerImages}>
                          <div className={styles.newConImage}>
                            <div
                              className={styles.newImg}
                              style={{
                                background: `url(${item.qsn_imgs[0]})`,
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                              }}
                            />
                          </div>
                          <div className={styles.newConImage}>
                            <div
                              className={styles.newImg}
                              style={{
                                background: `url(${item.qsn_imgs[1]})`,
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                              }}
                            />
                          </div>
                        </div>
                        <div className={styles.newContainerEstadisticas}>
                          <div className={styles.newSubContEstadisticas}>
                            <span className={styles.newTxtEstadisticas}>  {item.record} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.newContainerElements}>
                      <div className={styles.newContainerBtn}>
                        <div
                          className={AnswerQ[index] === 0 ? styles.newBtnGana2 : styles.newBtnGana}
                          onClick={(event) => {
                            if (isClickable) {
                              NewGuardarRespuesta(0, item.game_ans[0], index);
                            }
                          }}
                        >
                          <span className={styles.newTxtWiner}>
                            {item.game_ans[0]}                             
                          </span>
                        </div>
                      </div>
                      {item.typebreak === true && !AnswerQ.includes(100) && (
                        <div className={styles.newContainerEditor}>
                          <div className={styles.newSeparator}>
                            <span className={styles.newTitleEditor}>
                              <strong>{typebreakTittle}</strong>
                              {/* Ícono con eventos onMouseOver y onMouseOut */}
                    <span
                      style={{ marginLeft: '8px', cursor: 'pointer', position: 'relative' }}
                      onMouseOver={() => setShowTooltip(true)}  // Mostrar tooltip al hacer mouseover
                      onMouseOut={() => setShowTooltip(false)}   // Ocultar tooltip al salir del mouse
                    >
                      <FaQuestionCircle />
                      {/* Tooltip que solo aparece cuando showTooltip es true */}
                      {showTooltip && (
                        <div
                          style={{
                            position: 'absolute',
                            backgroundColor: 'black',
                            color: 'white',
                            padding: '5px',
                            borderRadius: '5px',
                            top: '-25px',  // Ajusta la posición del tooltip
                            left: '-300px',
                            zIndex: 10,
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {typebreakText}  {/* Mostrar el valor de typebreakText */}
                        </div>
                      )}</span>
                            </span>
                          </div>
                          <TextEditor
                            value={inputValue}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            index="typebreak"
                          />
                        </div>
                      )}
                      <div className={styles.newContainerBtn}>
                        <div
                          className={AnswerQ[index] === 1 ? styles.newBtnGana2 : styles.newBtnGana}
                          onClick={(event) => {
                            if (isClickable) {
                              NewGuardarRespuesta(1, item.game_ans[1], index);
                            }
                          }}
                        >
                          <span className={styles.newTxtWiner}>
                            {item.game_ans[1]}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}
  
                {!isClickable && item.typebreak && (
                  <>
                    {console.log("5.!isClickable && item.typebreak &&|Mostrando resultados typebreak")}
                    <Fragment>
                      <div className={styles.newContent_answwer_final}>
                        <div className={styles.newContainerImages_final}>
                          <div className={styles.typebreakResults}>
                            <strong>Resultados typebreak:</strong>
                            <span>{` ${item.game_result_1 + item.game_result_2}`}</span>
                          </div>
                        </div>
                      </div>
                      <hr className={styles.divider} />
                    </Fragment>
                  </>
                )}
              </>
            )}
          </Fragment>
        ))}
        {otros.length > 0 && (
          <>
            {console.log("Mostrando otros diseños")}
            <OldDiseño />
          </>
        )}
      </div>
    );
  };
  
  

  const VisibilityAnswers = () => {
    const initialNumAnswer = AnswerQ[parseInt(localStorage.getItem("n_question"))];
    const [numAnswer, setNumAnswer] = useState(initialNumAnswer);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    useEffect(() => {
      const updateNumAnswer = AnswersData.qsn_imgs[parseInt(localStorage.getItem("n_question"))];
      setNumAnswer(updateNumAnswer);
    }, []);
  
    return (
      <div className={styles.content_answwer2}>
        {isMobile ? (
          <MobileVisibility
            numAnswer={numAnswer}
            styles={styles}
            AnswersData={AnswersData}
          />
        ) : (
          <PCVisibility
            numAnswer={numAnswer}
            styles={styles}
            AnswersData={AnswersData}
          />
        )}
      </div>
    );
  };

  const MobileVisibility = ({ numAnswer, styles, AnswersData }) => {
    setNumAnswer(AnswerQ[parseInt(localStorage.getItem("n_question"))]);
  
    return (
      <Fragment>
        <div className={styles.content_slider_img}>
          <Swiper
            spaceBetween={40}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            className={styles.sliderImg}
          >
            {AnswersData.qsn_imgs.map((item, index) => (
              <SwiperSlide key={index} className={styles.contenImg}>
                <div
                  className={styles.contenImg}
                  style={{
                    background: `url(${item})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={styles.contentTitle}>
          <span className={styles.txtQuestionTitle}>
            {AnswersData.game_ques}
          </span>
        </div>
        <TextAnswerColor />
        <ButtonVisibility />
      </Fragment>
    );
  };

  const PCVisibility = () => {
    setNumAnswer(AnswerQ[parseInt(localStorage.getItem("n_question"))]);

    return (
      <Fragment>
        <div className={styles.content_slider_img}>
          <Swiper
            spaceBetween={40}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            className={styles.sliderImg}
          >
            {AnswersData.qsn_imgs.map((item, index) => (
              <SwiperSlide key={index} className={styles.contenImg}>
                <div
                  className={styles.contenImg}
                  style={{
                    background: `url(${item})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={styles.contentTitle}>
          <span className={styles.txtQuestionTitle}>
            {AnswersData.game_ques}
            <TextAnswerColor />
            <ButtonVisibility />
          </span>
        </div>
      </Fragment>
    );
  };
  
  const ButtonEnd = () => {
    // Verificar que todas las respuestas estén completas y que el typebreak tenga un valor
    const allAnswersCompleted = !AnswerQ.includes(100);  // Comprobar si todas las respuestas están completas
    const typebreakCompleted = String(inputValue).trim() !== "";  // Comprobar si el typebreak tiene un valor
          if (!allAnswersCompleted) return null;

      // Mostrar el botón solo si todas las respuestas están completas
    
      return (
        <div
          className={styles.contenButtonSend}
          onClick={() => {
            // Validación de typebreak justo al presionar el botón "Enviar respuestas"
            if (ALLApiData.some((item) => item.type === "juego") && !typebreakCompleted) {
              toast.error("Debes ingresar un valor para el desempate antes de enviar.");
            } else {
              // Mostrar la alerta si todo es válido
              setAler(true);
            }
          }}
        >
          <span className={styles.txtAnswers}>{"ENVIAR RESPUESTAS"}</span>
        </div>
      );
    };
    

  const OldDiseño = () => {
    return (
      <div className={styles.carContent}>
        {ALLApiData.map((item, index) => {
          return (
            <div
              id="tarjeta_answer"
              className={
                AnswerQ[index] === 100 ? styles.cardColor : styles.cardColor21
              }
              key={index}
              onClick={(event) => {
                if (isClickable) {
                  setVisivility("Answers");
                  localStorage.setItem("n_question", index);
                }
              }}
            >
              <div className={styles.content_img}>
                {item.type === "pregunta" ? (
                  <div
                    className={styles.img}
                    style={{
                      background: `url(${item.qsn_imgs[0]})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                ) : (
                  <div className={styles.img_content2}>
                    <img
                      src={item.qsn_imgs[0]}
                      className={styles.imagenPrueba1}
                    />
                    <img
                      src={item.qsn_imgs[1]}
                      className={styles.imagenPrueba1}
                    />
                  </div>
                )}
              </div>
              <div className={styles.text_content}>
                <span className={styles.txt_question}>{item.game_ques}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const VisibilityQuestions = () => {
    return (
      <div className={styles.contentSupremo}>
        <div className={styles.txtSubTitle}></div>
        {!Loading ? <NewDiseño /> : <></>}
        <div className={styles.contenButtonQuestion}>
          <ButtonEnd />
        </div>
        <div className="m-5"></div>
      </div>
    );
  };

  const MostrarLoading = () => {
    return (
      Loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )
    );
  };

  return (
    <>
      {<MostrarLoading />}
      <AlertDialogPerson /> 
      <AletrtDialogConfir />
      <div className="p-2"></div>
      <div className={styles.contenedorInicial}>
        <Visibilidad />
      </div>
    </>
  );
};

export default Tab3Content;